@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
    font-family: "Lato", system-ui, sans-serif;
  }
  .dark {
    --accent-primary: #0064ff;
    --accent-secondary: #b100e8;
    --object-primary: #00143c;
    --object-secondary: #80899d;
    --placeholder: #b9bec8;
    --border: #d9dfe7;
    --background: #eceff4;
    --highlight: #f6f8f9;
    --base: #fff;
    --success: #1bb560;
    --warning: #e59f02;
    --error: #cf3a1f;
    --dark-bg: #ffffff;
  }
 
  :root {
    --text: #d9dfe7;
    --accent-primary: #3282ff;
    --accent-secondary: #c91cff;
    --object-primary: #ffffff;
    --object-secondary: #99a1b1;
    --placeholder: #475673;
    --border: #293a5b;
    --background: #00143c;
    --highlight: #14274c;
    --base: #0a1d44;
    --success: #1ec96b;
    --warning: #ffb102;
    --error: #e64022;
    --dark-bg: #ffffff;

    .code-input-container {
      height: 40px;
      width: 280px;
    }
    .code-input-character {
      width: 40px;
      max-width: 40px;
      height: 40px;
      border: 1px solid #293a5b;
      font-size: 16px;
      border-radius: 6px;
      line-height: 40px;
     
      /* dark theme */
      color: #fff;
      background-color: transparent;
    }
    .code-input-character-error {
      width: 40px;
      max-width: 40px;
      height: 40px;
      border: 1px solid #cf3a1f;
      font-size: 16px;
      border-radius: 6px;
      line-height: 40px;
     
      /* dark theme */
      color: #fff;
      background-color: transparent;
    }
    .code-input-character--inactive {}
    .code-input-character--selected {}
    .code-input-character--filled {}
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background;
  }
}